<script>
  import Base from '@backend/Base.vue';
  import BOGen from "@helper/BOGen";
  import Gen from "@helper/Gen";
  export default {
    extends: Base,
    data() {
      return {
        Name: "BoDashboard",
        site_name: "",
        news: 0,
        info: 0,
        product: 0,
        ask: [],
        viewedProd: [],
        viewedArticles: [],
        mostView: [],
        newPPDB: [],
        upcomingEvents: [],
        mostViewedNewsArticle: [],
        PageName: 'Dashboard',
      }
    },
    computed: {
      ufullname() {
        return this.user ? this.user.bu_full_name : "-"
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {}
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
    
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Howdy {{ufullname}}</h5>
            <br> Selamat datang di halaman Administrator SDI Al Hidayah! Saat ini Anda berada di halaman TERPROTEKSI.
            <br><br>
            <p> Halaman ini khusus diperuntukkan HANYA untuk Administrator website yang akan melakukan pengelolaan konten.<br>
              Sebelum melakukan pengaturan, berikut beberapa hal yang perlu diperhatikan: <br><br>

              1. Simpan dan amankan Username dan Password Anda untuk menghindari hal-hal yang tidak diinginkan.<br>
              2. Siapkan dahulu materi/bahan yang akan digunakan dalam konten yang akan di update.<br>
              3. Siapkan juga foto, video atau material lain yang terkait, untuk memudahkan proses update.<br>
              4. Gunakan browser versi terbaru untuk mendapatkan kompatibilitas fitur-fitur dalam halaman ini.<br>
              5. Jika ada pertanyaan tentang penggunaan fitur-fitur di halaman ini, silakan kontak web developer di bawah ini:<br>
              <p>
                Kontak Pengembang Web:<br>
                Email : hello@lingkar9.com<br>
                Telepon : +62-21-744-6929
              </p><br />
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5 class="card-title">Daftar PPDB Terbaru</h5>
        <div v-if="newPPDB.length > 0" >
          <router-link
            :to="{ name: 'BoPPDB' }"
            class="btn btn-info btn-rounded"
            v-if="moduleRole('Add')"
            >Lihat Semua<i class="fa fa-arrow-circle-right m-l-10" aria-hidden="true"></i></router-link
          >
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <th>#</th>
              <th>Nama Murid</th>
              <th>Email Orang Tua</th>
              <th>Kelas</th>
              <th>Tanggal Daftar</th>
            </thead>
            <tbody v-if="newPPDB.length > 0">
              <tr v-for="(v,k) in newPPDB.slice(0, 5)" :key="k">
                <td>{{k+1}}</td>
                <td>{{ v.ap_sname || "-" }}</td>
                <td>{{ v.ap_memail || "-" }}</td>
                <td>{{ v.ap_sclass_register || "-" }}</td>
                <td>{{ (v.ap_create_at || "").dates("format") || "-" }}</td>
              </tr>
            </tbody>
            <tbody v-else> <tr><td colspan="6" align="center">Tidak ada data yang tersedia</td></tr></tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5 class="card-title">Acara Mendatang</h5>
        <div v-if="upcomingEvents.length > 0">
          <router-link
            :to="{ name: 'BoCalendar' }"
            class="btn btn-info btn-rounded"
            v-if="moduleRole('Add')"
            >Lihat Semua<i class="fa fa-arrow-circle-right m-l-10" aria-hidden="true"></i></router-link
          >
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <th>#</th>
              <th>Nama Kegiatan</th>
              <th>Tanggal dan Waktu</th>
              <th>Lokasi</th>
            </thead>
            <tbody v-if="upcomingEvents.length > 0">
              <tr v-for="(v, k) in upcomingEvents.slice(0, 5)" :key="k">
                <td>{{ k + 1 }}</td>
                  <td>{{ v.aca_name }}</td>
                  <td>{{ v.aca_date_start.dates('format2') }} - {{ v.aca_date_end.dates('format2') }}</td>
                  <td v-if="v.aca_location === null">-</td>
                  <td v-else>{{ v.aca_location }}</td>
              </tr>
            </tbody>
            <tbody v-else> <tr><td colspan="6" align="center">Tidak ada event yang tersedia</td></tr></tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5 class="card-title">Berita &amp; Artikel yang Paling Banyak Dilihat</h5>
        <div v-if="mostViewedNewsArticle.length > 0">
        <router-link
          :to="{ name: 'BoNews' }"
          class="btn btn-info btn-rounded"
          v-if="moduleRole('Add')"
          >Lihat Semua<i class="fa fa-arrow-circle-right m-l-10" aria-hidden="true"></i></router-link
        >
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <th>#</th>
              <th>Judul</th>
              <th>Dilihat</th>
              <th>Tipe</th>
              <th>Kategori</th>
              <th>Tanggal Terbit</th>
            </thead>
            <tbody v-if="mostViewedNewsArticle.length > 0">
              <tr v-for="(v, k) in mostViewedNewsArticle.slice(0, 5)" :key="k">
                <td>{{ k + 1 }}</td>
                  <td><router-link
                                v-if="moduleRole('Edit')"
                                :to="{
                              name: 'NewsArticleDetail',
                              params: { slug: v.an_slug },
                            }"
                            target="_blank"
                                >{{ v.an_title }}</router-link></td>
                  <td>{{ v.an_view }}</td>
                  <td>{{ v.anc_type == 1 ? 'Artikel' : 'Berita' }}</td>
                  <td>{{ v.anc_category }}</td>
                  <td>{{ v.an_publish_date }}</td>
              </tr>
            </tbody>
            <tbody v-else> <tr><td colspan="8" align="center">Tidak ada data yang tersedia</td></tr></tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>